import React from "react";
// import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import contact1 from "../images/about1.jpg";
// import { FaEnvelope, FaMapMarkedAlt } from 'react-icons/fa';
// import { MdPhone } from 'react-icons/md';
import "../css/plans.css";

class ContactPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Contact Us" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Warranty Service Request</h1>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-sm">

            <p className="text-muted">Please fill out the form below and we will be in touch with you soon.</p>

            <form name="warranty-service-request" data-netlify-honeypot="bot-field" action="/contact-success"  method="POST" data-netlify="true">

                <div className="hidden">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </div>

                <input type="hidden" name="form-name" value="warranty-service-request" />

                <div className="form-group">
                  <label htmlFor="contactName">Name</label>
                  <input type="text" name="name" className="form-control" id="contactName" placeholder="Enter your name"/>
                </div>

                <div className="form-group">
                  <label htmlFor="emailAddress">Email address</label>
                  <input type="email" name="email" className="form-control" id="emailAddress" aria-describedby="emailHelp" placeholder="Enter email"/>
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input type="text" name="phone" className="form-control" id="phone" aria-describedby="phoneHelp" placeholder="Phone Number"/>
                </div>

                <div className="form-group">
                  <label htmlFor="propertyAddress">Property address</label>
                  <input type="text" name="propertyAddress" className="form-control" id="propertyAddress" aria-describedby="propertyHelp" placeholder="Property address"/>
                </div>

                <div className="form-group">
                  <label htmlFor="messageInput">Description</label>
                  <textarea name="description" className="form-control" id="messageInput" rows="3"></textarea>
                </div>


                <button type="submit" className="btn btn-success btn-lg float-right mb-4">SEND</button>

            </form>

            </div>

          </div>

        </div>

      </Layout>
    )
  }
};

export default ContactPage;
